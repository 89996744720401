.modal-body .payment-integration-modal div {
    margin-bottom: 0;
}

.modal-body .payment-integration-modal .card {
    margin-bottom: 30px;
}

.integration-usage-container .form-switch {
    float: left;
    margin-right: 15px;
}

.integration-usage-container .form-switch-label {
    padding-top: 5px;
    font-size: 0.957rem;
}

i.create-api-key-btn {
    position: relative;
    padding-right: 5px;
}

button.btn.btn-primary.create-api-key-btn {
    margin-top: 1.2em;
}
  