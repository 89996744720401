

.header-fixed > tbody {
  overflow-y: auto;
  /* height: 340px; */
}

.net-energy-table table tr {
  border: none;
}

.net-energy-table.scrollable {
  margin-top: 3em;
  max-height: 340px;
  overflow-y: auto;
}
.net-energy-table .table>:not(caption)>*>* {
  border-bottom: none;
}
.header-fixed thead {
  border-bottom: 1px solid #C1C5C8;
}
.net-energy-table {
  position: relative;
}

.net-energy-table-numeric {
  text-align: right;
}
