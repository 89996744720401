@import '../../Variables.scss';

.import-device-site-list {
border-bottom: 1px solid #DCE3EB;
padding-top: 25px;
}

.import-device-site-heading {
font-size: 0.938rem;
line-height: 30px;
padding-bottom: 8px;
color: $appTextDarkGrey;
font-family: $appFontMedium;
}

.import-device-site-heading input[type='checkbox'] {
margin-right: 5px;
}
.import-device-item .form-switch {
float: left;
}

.import-device-item input[type='checkbox'] {
margin-right: 3px;
}
.import-device-site-heading .form-check-input[type=checkbox],
.import-device-item .form-check-input[type=checkbox]{
background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' /%3e%3c/svg%3e");
border-radius: 3px;
}

.import-device-item .form-check-input[type=checkbox] {
width: 1.2em;
height: 1.2em;
}

.import-device-site-heading .import-check-input {
width: 1.25em;
height: 1.25em;
}

h2.import-device-site-heading {
line-height: 22px;
padding-bottom: 10px;
}

div.import-device-item {
padding-left: 28px;
padding-bottom: 8px;
margin-bottom: 0px;
}

.import-device-item .form-switch {
margin-left: 0px;
padding-left: 1.6em;
}

.import-device-item .form-switch .form-check-input {
margin-left: 0;
margin-right: 10px;
}

div.import-device-item input[disabled]{
text-decoration: line-through;
}

div.import-device-item label,
.import-site-label {
padding: 0;
margin: 0;
}

.import-device-container .swyched-spinner {
font-size: 60px;
color: #bbb;
text-align: center;
display:flex;
align-items: center;
justify-content: center;
}

div.import-device-item label {
font-size: 0.875rem;
padding-bottom: 10px;
line-height: 24px;
}
  