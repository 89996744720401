@import '../../Variables.scss';

.flex-header {
    display: flex;
  }
  
.flex-header .connector-index {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: $appTextGrey;
    position: relative;
    top: 4px;
}

.connector-config .flex-header .btn {
    padding: 0px 4px;
}

.connector-config:last-of-type {
    margin-bottom: 20px;
}
.connector-config .flex-header {
    margin-top: 12px;
}

.connector-config .connector-index {
    font-size: 1rem;
}

.connector-type {
    position: relative;
}