// App Colors
$appPrimaryBlue: rgb(23, 87, 133);
$appSecondaryGrey: rgba(193, 197, 200);
$appPrimaryWhite: #e5e5e5;

$appTextDarkGrey: #555;
$appTextGrey: #aaa;

$appGreen: #82C882;
$appLightBlue: #6899E3;
$appLightRed: #EF553F;
$appYellow: #FECF56;

$coreWhite: #fff;

$appFontRegular: 'GalanoGrotesqueRegular', sans-serif;
$appFontMedium: 'GalanoGrotesqueMedium', sans-serif;
$appFontBold: 'GalanoGrotesqueSemiBold', sans-serif;

// Media query breakpoints
$breakpoints: (
  "phone": 360px,
  "tablet": 768px,
  "desktop": 1366px,
  "LGdesktop": 1920px
) !default;

// Device status colors
$color-status-available: rgba(130, 200, 130, 1);
$color-status-faulted: rgba(228, 61, 48, 1);
$color-status-suspended: rgba(254, 213, 86, 1);
$color-status-charging: rgba(104, 153, 227, 1);
$color-status-default: rgba(193, 197, 200, 1);
$color-status-idle: rgba(193, 197, 200, 1);
$color-status-finishing: rgba(156, 139, 204, 1);
$color-status-reserved: rgba(251, 172, 99, 1);
$color-status-unknown: rgba(232, 238, 244, 1);
$color-status-preparing: rgba(106, 200, 206, 1);

// Access card status colors
$color-status-authorized: rgba(130, 200, 130, 1);
$color-status-disabled: rgba(193, 197, 200, 1);

// Ticket status colors
$color-ticket-status-open: rgba(104, 153, 227, 1);
$color-ticket-status-pending: rgba(254, 207, 86, 1);
$color-ticket-status-solved: rgba(130, 200, 130, 1);
$color-ticket-status-closed: rgba(193, 197, 200, 1);


