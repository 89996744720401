@import '../Variables.scss';

ul.swyched-date-select-preset {
  padding: 4px;
  cursor: pointer;
  padding-right: 15px;
  padding-bottom: 15px;
  min-width: auto;
  padding-top: 15px;
  padding-left: 6px;
}
li.swyched-date-select-preset-item {
  padding: 1px 10px;
  color: $appTextDarkGrey;
  cursor: pointer;
}

.swyched-date-select-preset-item:hover {
  background: #F6F7F7;
}

.swyched-date-select-preset-item:last-child {
  margin-top: 8px;
}

.swyched-date-select-preset-item.selected {
  background: #F6F7F7;
  color: rgb(52, 70, 117, 0.9);
  border-radius: 3px;
}

.swyched-date-select-custom {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
.ant-picker-focused {
  border-color: #175785;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}
.ant-picker:hover, .ant-picker-focused {
  border-color: #175785;
  border-right-width: 1px !important;
}

.ant-picker-range {
  position: relative;
  display: inline-flex;
  padding: 15px;
  width: 90%;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #175785;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #175785;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: rgba($appPrimaryBlue, 0.1) !important;
}

.ant-picker-input > input {
  font-family: $appFontRegular;
}

.ant-picker-content th {
  height: 30px;
  color: $appTextDarkGrey;
  line-height: 30px;
  background: #f8f8f8;
  font-family:  $appFontMedium;
}
.ant-picker-range .ant-picker-active-bar {
  background: #175785;
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
  font-family: $appFontMedium;
  color: $appTextDarkGrey;
}
.ant-picker-range .ant-picker-active-bar {
  height: 3px;
}
.ant-picker-cell-in-view {
  color: $appTextDarkGrey;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover {
  border-color: rgba($appPrimaryBlue, 0.1) !important;
}

.ant-picker-header-view button:hover {
  color: rgba($appPrimaryBlue, 1);
  text-decoration: underline; 
}