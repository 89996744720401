@import '../Variables.scss';

.card-table .swyched-spinner{
  color: #C1C5C8;
  font-size: 40px;
}
.access-control .card-body h2 {
  font-size: 1.938rem;
  color: $appTextDarkGrey;
  font-weight: 300;
  padding-bottom: 1.2em;
}

.card-table i.icon, i.icons {
  font-size: 1.2em;
}

.access-control-group-select {
  display: none;
  margin-bottom: 30px;
}

.access-control .card,
.access-control-plans .card {
  padding: 20px;
  margin-top: 0;
}

.access-control h2, 
.access-control-plans h2 {
  font-size: 23px;
}

.access-control-group-select {
  margin-bottom: 40px;
}

.status-authorized {
  background: rgba($color-status-authorized, .2);
}

.status-authorized .status-square-indicator {
  background: rgba($color-status-authorized, 1);
}

.status-disabled {
  background: rgba($color-status-disabled, .2);
}

.status-disabled .status-square-indicator {
  background: rgba($color-status-disabled, 1);
}