@import '../Variables.scss';

.drawer {
  height: 100%;
  background: #fff;
  // box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 100;
  transform: translateX(-100%);
  transition: all 0.5s ease 0s;
  color: #eee;
  overflow-y: auto;
}

.drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
  top: 0;
  right: 0;
}

.drawer.open {
  transform: translateX(0);
}
.drawer-header {
  padding: 28px 40px 0px 40px;
}

.drawer-header h2 {
  font-size: 1.25rem;
  color: $appTextDarkGrey;
}

.drawer-body {
  position: relative;
  height: auto;
}