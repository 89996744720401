@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}

@font-face {
  font-family: 'GalanoGrotesqueLight';
  font-style: normal;
  font-weight: 400;
  // src: url('../fonts/GalanoGrotesqueLight.woff'); /* IE9 Compat Modes */
  src: local('GalanoGrotesqueLight'), local('GalanoGrotesqueLight'),
       url('./fonts/GalanoGrotesqueLight.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/GalanoGrotesqueLight.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'GalanoGrotesqueSemiBold';
  font-style: normal;
  font-weight: 400;
  // src: url('../fonts/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('GalanoGrotesqueSemiBold'), local('GalanoGrotesqueSemiBold'),
       url('./fonts/GalanoGrotesqueSemiBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/GalanoGrotesqueSemiBold.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'GalanoGrotesqueRegular';
  src: url('./fonts/GalanoGrotesqueRegular.woff2') format('woff2'),
      url('./fonts/GalanoGrotesqueRegular.woff') format('woff'),
      url('./fonts/galano/GalanoGrotesque-Regular.svg#GalanoGrotesque-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesqueMedium';
  src: url('./fonts/galano/GalanoGrotesque-Medium.eot');
  src: url('./fonts/galano/GalanoGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GalanoGrotesqueMedium.woff2') format('woff2'),
      url('./fonts/GalanoGrotesqueMedium.woff') format('woff'),
      url('./fonts/galano/GalanoGrotesque-Medium.svg#GalanoGrotesque-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
