.pagination-control-container {
  margin: 10px 0px;
  height: 45px;
}

.pagination-control-container > div {
    position: relative;
    display: inline-block;
    margin: 2px;
    margin-bottom: 0;
    padding-bottom: 0;
    cursor: default;
    user-select: none;
    text-align: center;
    cursor: pointer;
    color: #666;
    line-height: 26px;
    padding: 0px 7px;
    font-size: 16px;
}

.pagination-control-container > div.pagination-control-button {
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.pagination-control-container > div.pagination-control-button span {
  position: relative;
  top: -1px;
  color: #C1C5C8;
}

.pagination-control-container > div.paginagion-control-page:hover,
.pagination-control-container > div.pagination-control-button:hover {
  color: $appTextDarkGrey;
  border-radius: 5px;
  background: rgba(193, 197, 200, 15%);
}

.pagination-control-container > div.pagination-control-selected {
  background: rgba(23, 87, 133, 0.05);
  color: #175785;
  border-radius: 5px;
}

