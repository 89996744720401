.swyched-popover-hotspot {
  position: relative;
}
.swyched-popover-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.swyched-popover {
  background: white;
  padding: 12px 16px;
  box-shadow: 
    0 3px 6px -4px rgb(0 0 0 / 12%), 
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  position: absolute;
  top: 142px;
  z-index: 100;
}

.swyched-popover::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 1em;
  top: 0.2em;
  left: 50%;
  box-sizing: border-box;
  border: 0.7em solid black;
  border-color: #fff #fff #fff #fff;
  transform-origin: 0 0;
  transform: rotate(-225deg);
  box-shadow: -4px 1px 4px 0px rgb(0 0 0 / 3%);
}
