.modal-body p.tap-card-desc {
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 0px;
    padding-top: 32px;
}

.tap-card-continue {
    text-align: center;
    padding-bottom: 15px;
}

.tap-rfid {
    margin: 0 auto;
    text-align: center;
}
  