@import '../Variables.scss';

.swyched-tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.swyched-tooltip {
  position: absolute;
  border-radius: 3px;
  left: 50%;
  transform: translateX(-50%);
  padding: 22px 26px;
  color: $appTextDarkGrey;
  background: #fff;
  border: 1px solid #DCE3EB;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  transition: opacity 0.5s ease-in-out 0.25s;
  opacity: 0;
  min-width: 365px;
  box-shadow: 0px 10px 25px -8px rgba(0, 58, 93, 0.2);
  top: -9000px;
}

.swyched-tooltip-parent:hover .swyched-tooltip {
  opacity: 1;
  display: block;
  top: 0px;
}


.swyched-tooltip.top::after {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.swyched-tooltip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.swyched-tooltip.bottom::after {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.swyched-tooltip.bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.swyched-tooltip.right::after {
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.swyched-tooltip.right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.swyched-tooltip.left::after {
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.swyched-tooltip.left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

@keyframes tooltip-fadein {
  0% { opacity: 0; }
  30% { opacity: 0; }
  100% { opacity: 1; }
}

dt,
dd {
  display: inline-block;
  margin: 0;
  font-family: $appFontRegular;
  line-height: 28px;
}

dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0
}
dt {
  float: left;
  width: 44%;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: $appTextGrey;
  text-transform: uppercase;
  
}
dd {
  float: left;
  width: 56%;
  padding: 0;
  margin: 0;
  color: $appTextDarkGrey;
  font-size: 1rem;
}

dd .beta-tag {
  position: relative;
  float: left;
  margin-left: 0;
  top: 2px;
}