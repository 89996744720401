@import '../Variables.scss';

/* .devices-container .card-body {
  padding: 1.4em 1.4em;
} */

.add-device-icon {
  font-size: 1.4rem;
}

.site-rate-container label {
  padding-bottom: 0.3em;
  font-size: 0.75rem;
}

.site-container .card {
  margin-bottom: 52px;
}

.site-container .card-body {
  padding: 3.7em;
  padding-top: 2.3em;
  padding-bottom: 40px;
}

h2.site-title {
  font-size: 31px;
}

.site-header-container {
  padding-top: 3em;
  margin: 0em -0.925em;
}
.site-header-container:first-of-type {
  padding-top: 0;
}

h5.site-secondary-header {
  font-size: 1rem;
  color: $appTextGrey;
  padding-bottom: 0.4375em;
  border-bottom: 1px solid #DCE3EB;
  margin-bottom: 0.35em;
}

.site-container hr {
  color: #DCE3EB;
  margin-bottom: 1.5em;
}

.site-breadcrumb li{
  display: inline;
  font-size: 1rem;
  color: $appTextGrey;
}

.site-breadcrumb ol, ul {
  padding-left: 0;
}

.site-breadcrumb span {
  position: relative;
  top: 6px;
  padding: 0em 0.4em;
}
.site-actions .cog.icon {
  font-size: 1rem;
}
.site-breadcrumb {
  display: none;
}
.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
  position: relative;
  padding-left: 2.871429em!important;
  padding-right: 1.5em!important;
}

.station-icon {
  float: left;
}

.menu .item svg {
  margin-right: 10px;
}

.device-list li {
  position: relative;
  padding: 1.25em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid $appSecondaryGrey;
  padding-left: 20px;
  padding-top: 22px;
  margin-top: 20px;
}

.device-list li:hover {
  background: #f8f9f9;
}
.device-list li:hover .btn-action i {
  background: #f8f9f9;
  transition: none;
  margin: 0px;
  transition: all 0s;
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  -moz-transition: all 0s;
  text-decoration: none;
}

.device-location-drop-target-hovering {
  outline: solid rgb(60, 157, 232) 2px;
  border-radius: 2px;
}

ul.device-list {
  padding: 0;
}

.device-list ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
  padding: 0;
}

.device-list li.device-highlight {
  outline: 4px solid rgba(23, 87, 133, 0.3);
  background: rgba(193, 197, 200, 0.15);
  -webkit-transition: outline 0.5s ease-out 3s;
  -moz-transition: outline 0.5s ease-out 3s;
  -o-transition: outline 0.5s ease-out 3s;
  -ms-transition: outline 0.5s ease-out 3s;
  transition: outline 0.5s ease-out 3s;
}

.device-list p {
  font-size: 0.75rem;
  color: $appTextGrey; 
}

.device-refresh-spinner {
  margin-left: 5px;
  font-size: 0.875rem;
  position: relative;
  top: 2px;
  color: #bbb;
}

.device-refresh-spinner i {
  color: #bbb;
}

.device-item-gutter {
  --bs-gutter-x: 2.05em;
}

.device-title {
  color: $appTextDarkGrey;
  font-size: 1rem; 
}

.add-device-btn .menu .item span.text {
  position: relative;
  top: 2px;
}

span.device-title.text-truncate {
  max-width: 225px;
  display: inline-block;
  margin-left: 14px;
  margin-top: 1px;
}

.device-title-icon {
  font-size: 1.4rem;
  color: $appPrimaryBlue;
  float: left;
}

.device-list .connector-list .connector-detail {
  padding: 0;
  margin: 0;
  margin-left: 6px;
}

.device-settings-btn {
  background: #ccc;
  border-color: #ccc;
  color: #fff;
}

.import-devices-btn {
  margin-right: 15px;
}

.add-device-btn i.icon.plus:before {
  content: "+";
  font-size: 27px;
  color: $appTextGrey;
}

.device-hub-title {
  padding-bottom: 0;
  margin-bottom: -12px;
}

ul.connector-list li.connector-detail  {
  border: none;
}

.no-data {
  text-align: center;
  color: $appTextGrey;
}

.device-select {
  margin-bottom: 40px;
  display: none;
}

.left-sidebar-list .faulted-icon {
  float: right;
  position: relative;
  top: 1px;
  display: inline-block;
}

.add-device-btn-container {
  position: relative;
}

.add-device-btn {
  position: absolute;
  right: 0px;  
}

.add-device-btn svg {
  position: absolute;
  left: 11px;
  top: 7px;
}

.add-device-btn .menu svg {
  position: relative;
  left: 0;
  top: 0;
}

.site-actions {
  cursor: pointer;
  color: $appSecondaryGrey;
  position: relative;
  top: 4px;
}

.device-actions {
  text-align: right;
  position: absolute;
  right: 20px;
  width: 50px;
}

.site-actions .btn-action,
.device-actions .btn-action {
  padding: 2px; 
  border-radius: 3px;
  transition: all .4s ease;
}

/* Hub */
.device-hub {
  border: 1px solid #C1C5C8;
  padding: 17px;
  border-radius: 5px;
  margin: 0;
  position: relative;
  padding-left: 17px;
  padding-top: 25px;
  margin-bottom: 40px;
  padding-bottom: 32px;
}

.device-hub .ui.dropdown .menu>.divider {
  margin: 0;
}

.device-hub>.device-actions:first-child {
  right: 28px;
}

.row > .device-hub:last-child {
  margin-bottom: 0px;
  padding-bottom: 32px;
}
.row > .device-hub:first-child {
  margin-top: 20px;
}

.device-hub-title .hub-name {
  font-size: 1rem;
  color: $appTextDarkGrey;
  position: relative;
  top: -2px;
}

span.hub-title {
  top: -5px;
  position: relative;
}

.device-hub-title span {
  font-size: 20px;
  color: $appPrimaryBlue;
  padding-right: 0.625em;
  float: left;
}

.hub-type {
  float: left;
  color: $appTextGrey;
  display: block;
  clear: left;
  display: inline-block;
  position: relative;
  bottom: 13px;
  left: 48px;
  font-size: 12px;
}

.device-hub-title-icon {
  padding-right: 1rem;
}

// /* New device / connector MW: might remove */ 

// .new-device {
//   text-align: center;
// }

// li.new-device i{
//   top: 0;
//   position: relative;
// }

// li.new-device {
//   font-size: 0.875rem;
//   color: #bbb;
//   padding: 14px;
// }

// .new-device i {
//   float: right;
// }

// li.new-connector {
//   font-size: 0.813rem;
//   color: #bbb;
//   text-align: center;
// }

// li.new-connector:hover,
// li.new-device:hover {
//   background: #f5f5f5;
//   color: $appTextGrey;
// }
// .new-connector i {
//   float: right;
// }


/* Device status indicator / bubble styles */

.status-faulted {
  background: rgba($color-status-faulted, .2);
}
.status-faulted .status-square-indicator {
  background: rgba($color-status-faulted, 1);
}
.status-available {
  background: rgba($color-status-available, .2);
}
.status-available .status-square-indicator {
  background: rgba($color-status-available, 1);
}
.status-charging {
  background: rgba($color-status-charging, .2);
}
.status-charging .status-square-indicator {
  background: rgba($color-status-charging, 1);
}
.status-default {
  background: rgba($color-status-default, .2);
}
.status-default .status-square-indicator {
  background: rgba($color-status-default, 1);
}
.status-reserved {
  background: rgba($color-status-reserved, .2);
}
.status-reserved .status-square-indicator {
  background: rgba($color-status-reserved, 1);
}
.status-preparing {
  background: rgba($color-status-preparing, .2);
}
.status-preparing .status-square-indicator {
  background: rgba($color-status-preparing, 1);
}
.status-finishing {
  background: rgba($color-status-finishing, .2);
}
.status-finishing .status-square-indicator {
  background: rgba($color-status-finishing, 1);
}
.status-unknown {
  background: rgba($color-status-unknown, .4);
}
.status-unknown .status-square-indicator {
  background: rgba($color-status-unknown, 1);
}
.status-suspended {
  background: rgba($color-status-suspended, .2);
}
.status-suspended .status-square-indicator {
  background: rgba($color-status-suspended, 1);
}

/* Connectors */

li.connector-detail {
  margin-top: 0;
  padding: 0.2em 0.3em;
}

ul.connector-list {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.connector-list li {
  border: none;
  box-shadow: none;
  display: inline-block;
  margin-left: 10px;
}

.connector-title {
  color: $appTextDarkGrey;
  font-size: 0.813rem;
  margin-left: 6px;
}

.connector-multiplier {
  font-size: 13px;
  color: #bbb;
  letter-spacing: 1px;
  line-height: 0px;
  top: 1px;
  left: 2px;
}

.connector-tooltip p{
  color: #fff;
}

.connector-type div {
  font-size: 0.688rem;
  line-height: 1.7;
  color: $appTextDarkGrey;
}