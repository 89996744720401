@import '../Variables.scss';

.report-filter {
  margin-top: 10px;
  border-bottom: 1px solid #DCE3EB;
  padding-bottom: 30px;
}

.control-label {
  margin-bottom: 0;
  padding: 0;
  margin-bottom: 4px;
  font-size: 1rem;
}

.report .card {
  padding: 40px;
}

.report .card-body {
  padding-top: 0;
}

.export-report {
  position: relative;
  top: 22px;
  width: 100%;
}

.export-report span {
  font-size: 21px;
  position: relative;
  top: 5px;
  height: 34px;
}

/* override bootstrap ruining align= */
th[align=right],
td[align=right] {
  text-align: right;
}

.col-without-label {
  top: 24px;
  position: relative;
}

h4.stop-reason-key-header {
  color: #666;
  font-size: 24px;
  
}

span.stop-reason-key-desc {
  color: #666;
  font-size: 0.938rem;
}

.list-group-item h5 {
  color: #666;
  font-size: 1.25rem;
}

.list-group-item:nth-child(odd) {
  background: #fbfbfb;
}

.refresh-cancel-btn {
  margin-left: 10px;
  background: #bbb;
  border-color: #bbb;
}

.export-report-container {
  position: absolute;
  right: 40px;
}

.export-report-btn {
  margin-left: 0px;
  padding: 0;
  float: left;
}

.export-report-container .beta-tag {
  margin-top: 8px;
}

.report-type-title {
  font-size: 28px;
}

.row.report-summary {
  display: block;
}

.report-summary {
  font-size: 0.875rem;
  color: #666;
}

.report-summary-list {
  padding: 15px 0px 0px 0px;
  padding-bottom: 2em;
  margin-left: 3px;
}

.list-group-item-action {
  padding: 1.5em;
}

ul.report-summary-list .list-group-item, 
ul.report-summary-list .list-group-item:nth-child(odd) {
  background: none;
  border: none;
}

ul.report-summary-list > li:first-child {
  padding-left: 10px;
}

.report-summary-item-title {
  color: $appTextGrey;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
}

.report-summary-item {
  color: $appTextDarkGrey;
  font-size: 16px;
}

.report .pagination-control-container {
  position: relative;
  top: 66px;
}

.report-date-range-summary .m-l-6 {
  margin-left: 0
}

.report-date-range-summary .material-icons-outlined {
  display: none;
}

.report-date-range-summary .centered-v {
  top: 0;
  transform: none;
}

.empty-report-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  color: #C1C5C8;
  padding: 9.25em 0em;
}

h2.empty-report-header {
  font-size: 25px;
  color: $appTextDarkGrey;
  margin-top: 15px;
}

.empty-report-container span {
  color: #C1C5C8;
  padding-bottom: 10px;
  margin: 0 auto;
  text-align: center;
}

.empty-report-text {
  font-size: 16px;
  line-height: 21.6px;
  margin-top: 8px;
  color: $appTextGrey;
}

h2.report-type-title  {
  font-size: 25px;
}

.row.report {
  clear: both;
}

.report-container .swyched-spinner {
  font-size: 40px;
}

.export-report-btn span {
  font-size: 30px;
}

.export-report-btn:hover svg {
  color: #212529;
  fill: $appTextDarkGrey;
}

.report-progress-container {
  margin-top: 20px;
}