@import './Variables.scss';
@import './Typography.scss';

body, html {
	height: 100%;
  font-family: $appFontRegular, Arial, Helvetica;
  background: #f8f8f8;
}

html {
  font-size: 100%;
}

a {
  font-family: $appFontRegular;
	color: $appPrimaryBlue;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  text-decoration: none;
}

b {
  font-family: $appFontBold;
} 
a:hover {
	text-decoration: underline;
  color: $appPrimaryBlue;  
}

h1,h2,h3,h4,h5,h6 {
	margin: 0px;
  font-family: $appFontRegular;
  color: $appTextDarkGrey;
}

p {
	font-family: $appFontRegular;
	font-size: 0.875rem;
	line-height: 1.7;
	color: $appTextDarkGrey;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}

.no-select {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


button {
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

.app-grey {
  color: $appTextGrey;
}

/* Login */

.container-login {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1.3125em;
  background: url(./seamless-background.svg) no-repeat center center fixed; 
}

.container-login h2 {
  font-size: 1.953rem;
  text-align: center;
  padding-bottom: 1em;
  color: $appPrimaryBlue;
}

.wrap-login {
  width: 425px;
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding: 2.9375em;
  box-shadow: 0px 10px 80px -20px rgb(0 58 93 / 50%);
}

.login-form {
  width: 100%;
}

.forgot-text {
  font-family: $appFontRegular, sans-serif;
  font-size: 0.875rem;
  color: $appPrimaryBlue;
  line-height: 1.4;
  position: relative;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.login-logo-container {
  font-size: 30px;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
}

/* Inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.signup-section label {
  padding-bottom: 0.2em;
  padding-top: 0;
}

.wrap-input {
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
}

.input {
  font-family: $appFontRegular;
  color: $appPrimaryBlue;
  line-height: 1.2;
  font-size: 1rem;
  display: block;
  width: 100%;
  background: transparent;
  height: 55px;
  padding: 0 20px 0 20px;
}


.focus-input {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid $appPrimaryBlue;
  border-radius: 3px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input:focus + .focus-input {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.input-checkbox {
  display: none;
}

.label-checkbox {
  font-family: $appFontRegular;
  font-size: 16px;
  color: $appTextGrey;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 0.813rem;
  color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: #fff;
  border: 2px solid $appPrimaryBlue;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox:checked + .label-checkbox::before {
  color: $appPrimaryBlue;
}

.container-login-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.btn.login-form-btn {
  font-family: $appFontMedium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  width: 100%;
  background-color: $appPrimaryBlue;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  margin-bottom: 25px;
}

a:focus,
label:focus,
input:focus {
  outline: 1px dotted;
}

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 3px;
  padding: 4px 25px 5px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: $appFontRegular;
  color: #c80000;
  font-size: 0.875rem;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 1.125rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.validation-error {
  color: $appLightRed;
  margin-top: 10px;
  position: relative;
  top: -10px;
  
  font-size: 0.75rem;
}

.validation-error.server-validation-error {
  top: 0px;
}
.forgot-container {
  position: relative;
}
.forgot-password {
  position: absolute;
  right: 0;
  top: 0px;
  text-align: right;
  display: block;
  font-size: 1rem;
  
}
.logo {
  width:  170px;
}

.header {
  width: 100%;
}
.header-right {
  position: absolute;
  right: 12px;
}
.header-right img {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  background: #fff;
  overflow: hidden;
  margin: 5px;
  padding: 2px;
}

h2.organization-title {
  float: left;
  font-size: 0.938rem;
  margin-top: 17px;
  margin-right: 4px;
}
.app-container {
  position: relative;
}

.header-container {
  height: 0px;
  background: #fff;
  box-shadow: 0px 0px 30px rgb(0 58 93 / 10%);
  z-index: 10;
  position: relative;
}

.add-custom-element {
  color: $appTextGrey;
  margin: 0 auto;
  display: block;
  margin-top: -1em;
}

.add-custom-element:hover,
.add-custom-element:hover span svg path {
  color: rgba($appPrimaryBlue, 1);
  fill: rgba($appPrimaryBlue, 1);
}

.add-custom-element span {
  position: relative;
  top: -3px;
  padding-right: 16px;
  display: inline;
}

.payment-plan h2 {
  font-size: 16px;
  line-height: 18px;
  text-shadow: none;
  font-family: $appFontRegular;
  margin-bottom: 30px;
  color: $appTextDarkGrey;
  text-transform: uppercase;
  margin-top: 10px;
}
.payment-plan-list li {
  margin-top: 10px;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid $appSecondaryGrey;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.10);
}
.payment-plan-list li:hover {
  background: $appPrimaryWhite;
}
.payment-plan-list li.selected {
  background: #efefef;
}

i.card {
  background: none;
  box-shadow: none;
}

.icon-container {
  display: inline;
}
.payment-plan p {
  font-size: 0.813rem;
  line-height: 1.8;
}
.payment-plan-list li {
  margin-top: 10px;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid $appSecondaryGrey;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.10);
  margin-bottom: 15px;
}

ul.payment-plan-list {
  margin-left: 0;
  padding: 0;
}
.border-right {
  border-right: 1px solid #efefef;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}


@media (max-width: 576px) {
  .container-table {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.app-sidebar {
  left: 0;
  position: fixed;
  width: 62px;
  background-color: #fff;
  height: 100%;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.15);
}
.app-sidebar a {
  display: block;
  text-align: center;
  padding: 15px 10px;
  transition: all 0.3s ease;
  border-bottom: 1px solid #eee;
  color: $appTextGrey;
}

.app-sidebar a:hover {
  background: #eee;
}
// .active {
//   background-color: #eee;
// }
.app-sidebar img {
  width: 45px;
}

.app-sidebar .collapsed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.app-sidebar .expanded{
  max-height: 100%;
}

img.popul8 {
    width: 27px;
}

i.fa.fa-plus,
i.fa.fa-sign-out {
    font-size: 1.188rem;
    margin-top: 8px;
    color: #ccc;
}
img.swyched-brandmark {
    width: 38px;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.card {
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow:  0px 10px 80px -30px rgba(0, 58, 93, 0.25);
  border-radius: 5px;
}

.card-body {
  padding: 1.4rem 1.4rem;
}

.page-title-container {
  padding: 0em 0em 3em 0em;
}
.sheet-control-container {
  padding-top: 2em;
  padding-left: 0;
  padding-right: 0;
}

.sheet-header.row {
    margin-left: 0;
    margin-right: 0;
}
.btn-primary svg {
  margin-right: 12px;
  position: relative;
  top: -1px;
}

h2.sheet-title {
  font-size: 39px;
  line-height: 33px;
  text-shadow: none;
  font-family: $appFontRegular, sans-serif;
  padding-bottom: 9px;
  color: #175785;
  position: relative;
  clear: both;
}

div.sheet-subtitle {
  color: $appTextGrey;
  font-size: 20px;
  line-height: 33px;
  font-family: $appFontRegular, sans-serif;
}

.sidebar-primary-btn {
  font-size: 0.875rem;
  width: auto;
}

.container-login input {
  border: none;
  outline: none;
  border: 1px solid $appSecondaryGrey;
  color: $appTextDarkGrey;
  border-radius: 3px;
  font-size: 16px;
}
.signup-section a {
  font-size: 1rem;
}
.signin-footer {
  text-align: center;
  font-size: 1rem;
  color: $appPrimaryBlue;
  
}
.signin-footer .horizontal-divider {
  padding: 0em 1em;
  display: inline-block;
  color: $appSecondaryGrey;
}

.toolbar-radio > input {
  display: none;
}

.toolbar-radio > i {
  padding: 2px;
}

.toolbar-radio > input:checked ~ i {
  background: #bbb;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23AAAAAA' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
}
.form-check-input:checked[type=radio] {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3e%3ccircle r="2.8" fill="%23175785"/%3e%3c/svg%3e');
  appearance: auto;
}

.modal-body .form-check-input[type=radio] {
  margin-right: 17px;
}
.modal-body .form-check-input[type=radio] {
  background-color: #fff;
  border-color: #175785;
}

label {
  display: block;
  margin: 0;
  color: $appTextDarkGrey;
  font-size: 1rem;
  padding: 0.8em 0em;
  cursor: pointer;
}

.status-bubble {
  padding: 3px 10px 3px 6px;
  border-radius: 3px;
  color: $appTextDarkGrey;
  font-size: 12px;
  display: inline-block;
  position: relative;
  font-family: $appFontRegular;
  margin-top: 5px;
}

.status-square-indicator {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  display: inline-block;
  background: $appTextDarkGrey;
  position: relative;
  top: 3px;
  margin-right: 9px;
}

.ui.button:hover,
.ui.button.btn-secondary:hover,
.ui.button.btn-secondary:active,
.ui.button:hover svg path,
.ui.button:active svg path {
  background: rgba($appSecondaryGrey, .15);
  color: $appTextDarkGrey;
  fill: $appTextDarkGrey;
}
.ui.button.dropdown.pull-right.btn.btn-primary {
  background: none;
  border-radius: 3px;
  color: $appTextGrey;
  font-family: $appFontRegular, sans-serif;
}
.payment-plan-title {
  color: $appTextDarkGrey;
  font-size: 0.813rem;
} 

i.icon {
  margin: 0;
}

.ui.dropdown .menu>.item>.flag,
.ui.dropdown .menu>.item>.icon,
.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>.label,
.ui.dropdown .menu>.item>img,
.ui.dropdown>.text>.flag,
.ui.dropdown>.text>.icon, 
.ui.dropdown>.text>.image,
.ui.dropdown>.text>.label,
.ui.dropdown>.text>img {
  margin-right: 12px;
  top: 2px;
}

.empty-container-overlay {
  color: $appSecondaryGrey;
  margin: 0 auto;
  padding: 6.25em 0em;
}

.empty-container-overlay i {
  font-size: 6.25rem;
  color: $appSecondaryGrey;
}

.empty-container-overlay p span {
  font-size: 1.125rem;
  line-height: 23px;
  margin-top: 12px;
  display: block;
  color: $appTextGrey;
}

.empty-state-header {
  font-size: 25px;
  max-width: 430px;
  margin: 14px auto 0;
  line-height: 33.75px;
  color: $appTextDarkGrey;
}

.empty-state-subheader{
  font-size: 16px;
  line-height: 21.6px;
  margin-top: 8px;
  color: $appTextGrey;
}

div.last {
  margin-bottom: 0;
}

.line {
  display: flex;
  flex-grow: 1;
  height: 1px;
  align-self: center;
  margin: 0 0px 0px 10px;
  background-color: #DCE3EB;
}

.delete-station-btn {
  color: $appTextGrey;
  padding-right: 0;
}

.delete-station-btn span {
  position: relative;
  top: 2px;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border-radius: 2px;
}

.signup-instructions {
  color: $appTextDarkGrey;
  line-height: 1.5;
  padding-bottom: 34px;
}

.pw-policy {
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 20px;
  margin-top: -10px;
  color: $appTextGrey;
}
.code-sent-text {
  padding-bottom: 1.5em;
}

.search-payment-plan {
  width: 20%;
}
tr:nth-child(odd) {
    background-color: #fefefe;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  width: 100px;
  height: 100px;
}

.hidden {
  display: none;
}

.spinner-container {
  width: 50px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 64;
  text-align: center;
}
.spinner {
  animation: spin infinite 5s linear;
  color: #ccc;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav{
  display: flex;
  justify-content: space-around;
  background-color: #2e3032;
  padding: 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  visibility: hidden;
}

.nav-item{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.nav-item i {
  color: #ccc;
  font-size: 28px;
}

.nav-item span{
  width: 0;
  overflow: hidden;
  line-height: 1;
}

.nav-item:focus{
  text-decoration: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  transition: .2s;
}
a.nav-item:hover {
  background: rgba(0,0,0,0.3);
  border-radius: 5px;
  color: #fff;
}

.nav-item:focus span{
  width: 100%;
  margin-left: 8px;
  transition: .2s;
}
nav a svg, 
nav a svg path {

  fill: $appTextGrey;
}
.mobile-nav-toggle {
  float: left;
  z-index: 3;
  position: relative;
  top: 16px;
  left: 20px;
  display: none;
}

.username {
  position: absolute;
  top: 0px;
  right: 50px
}

h2.organization-title {
  position: absolute;
  top: 0px;
  right: 50px;
  font-size: 0.875rem;
}

.ant-select-selector {
  border: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $appTextGrey !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  // border-color: $appTextGrey !important;
  // box-shadow: 0 0 0 2px rgb(0 0 0 / 20%) !important;
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px !important;
  border: none;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba($appPrimaryBlue, 0.1) !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: rgba(193,197,200,.15) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px !important;
  font-size: 1rem;
  padding-right: 0px;
}
span.ant-select-arrow {
  display: none;
}
span.ant-select-tree-title {
  color: $appTextDarkGrey;
  font-size: 16px;
  line-height: 40px;
}
.ant-select-tree-indent-unit {
  width: 12px !important;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  padding: 6px !important;
  padding-top: 8px !important;
}
.tab-tab.active-tab, .tab-tab.inactive-tab {
  display: inline-block;
  border-top: 0;
  border-left: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 8px;
}

.tab-tab {
  cursor: pointer;
  color: $appTextDarkGrey;
  user-select: none;
  padding: 17px 25px 15px 25px;
}

.tab-tab.active-tab {
  border-bottom: white;
  background: rgba(23, 87, 133, 0.05);
  border-bottom: 3px solid #175785;
}
.top-nav.tab-tab.active-tab {
  border-bottom: 2px solid #175785;
}

.top-nav.tab-tab .active-tab {
  height: 65px;
}
.tab-body {
  width: 100%;
  top: -1px;
  position: relative;
  padding: 4.563em 3.75em 3.75em 3.75em;
  background: #f8f8f8;
  border-top: none;
  padding-top: 74px;
  padding-left: 55px;
  overflow: auto;
}
.tab-tab i {
  position: relative;
  top: 4px;
  color: $appTextDarkGrey;
  display: inline-block;
}
.inactive-tab.tab-body {
  display: none;
}

.tab-control-container {
  transition: all 0.5s ease 0s;
}
.tab-control-container.mobile-menu-open {
  transform: translate3d(300px, 0px, 0px);
}

.org-select-container {
  width: auto;
  float: right;
  position: relative;
  top: 8px;
  right: 22px;
  z-index: 3;
  overflow: none;
}

.org-select-icon-placeholder {
  display: inline-block;
  width: 40px;
  position: relative;
  top: -2px;
  /* right: auto; */
  padding-right: 10px;
}

.org-select-icon {
  width: 44px;
  padding: 0px;
  font-size: 23px;
  text-align: center;
  color: #fff;
  top: -2px;
  right: 0px;
  vertical-align: middle;
  line-height: 34px;
}

.ant-select-tree-title .org-select-icon {
  width: 30px;
  position: relative;
  top: 5px;
  left: 4px;
}

.ant-select-tree-switcher .ant-tree-switcher-icon svg, .ant-select-tree-switcher .ant-select-tree-switcher-icon svg path
{
  d: path('M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z');
  fill: rgba($appSecondaryGrey, 1);
  width: 20px;
}
.ant-select-tree-switcher .ant-tree-switcher-icon, .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 15px;
}
.ant-select-tree-switcher {
  margin-right: 5px;
}
span.ant-select-arrow {
  color: $appTextDarkGrey;
  margin-top: -5px;
}

.ant-select-tree-list {
  padding-right: 15px;
}

@at-root {
  .ant-select-tree-list-holder {
     overflow: auto !important;
     max-height: none !important;
  }

  .ant-select-tree-list-scrollbar-thumb {
     display: none;
  }
}
p {
  margin-bottom: 0 !important;
}

.tab-tab span {
  position: relative;
  top: -3px;
  margin-left: 10px;
  font-size: 14px;
}

.active-tab.tab-tab span {
  color: $appPrimaryBlue;
}

.active-tab.tab-tab i {
  color: $appPrimaryBlue;
}
.active-tab.tab-tab path {
  fill: $appPrimaryBlue;
}
.tab-tab svg {
  display: block;
}

.tab-control-tabs {
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 30px rgb(0 58 93 / 10%);
  background: #fff;
}
.top-nav.tab-control-tabs {
  height: 65px;
}

.remove-btn {
  margin-right: 0px;
}

.ant-select-tree-switcher .ant-tree-switcher-icon, .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  top: 15px !important;
  position: absolute !important;
  color: $appTextDarkGrey !important;
}

img.charger-icon {
  position: relative;
  width: 38px;
}

.charger-icon.faulted-status {
  color: #b33;
}

.charger-icon.icon-ccs {
  width: 38px;
}

.user-avatar {
  float: right;
  width: 40px;
  background: #ccc;
  position: relative;
  z-index: 3;
  top: 8px;
  right: 20px;
  border-radius: 2px;
  height: 40px;
}

.avatar-icon {
  font-size: 24px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  top: 3px;
  position: relative;
}

.user-status {
  border: 1px solid #fff !important;
  background-color: #4eac7e !important;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  position: absolute;
  left: 35px;
  top: -3px;
}

.user-role {
  background: $appPrimaryBlue;
  padding: 4px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.813rem;
}

.viewer-tag {
  background: #4eac7e;
}

.admin-tag {
  background: $appPrimaryBlue;
}

h2 {
 color: $appTextDarkGrey;
}

h5 {
  color: $appTextGrey;
}


.empty-list-banner {
  padding: 12px;
  width: 75%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.payment-plan-container.card {
  padding: 20px;
  margin-top: 0;
}

/* Left sidebar filter */

.left-side-bar-filter ul {
  margin: 0;
  padding: 0;
}

.left-side-bar-filter li {
  color: $appTextDarkGrey;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  padding: 0 12px;
}

.left-side-bar-filter li svg {
  color: $appTextGrey;
  vertical-align: middle;
  position: relative;
  visibility: hidden;
}

.left-side-bar-filter li.left-sidebar-add-new i {
  visibility: visible;
}

.left-side-bar-filter li:hover .left-sidebar-control-icon svg {
  visibility: visible;
}
.left-side-bar-filter li .item svg {
  visibility: visible;
}

.left-side-bar-filter li.selected {
  background: rgba(23, 87, 133, 0.1);
  color: #175785;
  border-radius: 3px;
}

.left-side-bar-filter li.selectable-empty {
  font-style: italic;
}

.left-side-bar-filter .left-sidebar-add-new {
  font-size: 1rem;
  color: $appTextGrey;
  line-height: 40px;
  border-radius: 3px;
}

.left-side-bar-filter .left-sidebar-add-new i {
  display: block;
}

.left-side-bar-filter li i {
  color: $appTextGrey;
  /* vertical-align: middle; */
  float: right;
  top: 5px;
  position: relative;
  visibility: hidden;
}
.left-side-bar-filter .item i {
  visibility: visible
}

.left-side-bar-filter hr {
  margin-bottom: 15px;
}

.left-side-bar-filter li:hover {
  background: rgba(193, 197, 200, 15%);
  border-radius: 2px;
}

.left-side-bar-filter li.left-sidebar-add-new svg {
  visibility: visible;
  top: 0;
  padding: 0;
  margin-top: 6px;
  float: right;
}

.left-side-bar-filter li.left-sidebar-add-new:hover svg path{
  fill: #175785;
}

.left-side-bar-filter li span.faulted-icon svg{
  visibility: visible;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.menu .item i {
  top: 0px;
}
.btn.control-plan {
  margin-bottom: 20px;
  font-size: 0.875rem;
  position: absolute;
  right: 35px;
  top: 30px;
}

.left-sidebar-control-icon {
  color: #bbb;
  vertical-align: middle;
  float: right;
} 

.access-group-column ul li {
	margin: 0px;
	list-style-type: disc;
}

li.left-sidebar-add-new i {
  top: 3px;
}
.left-sidebar-add-new:hover {
  color: $appPrimaryBlue;
  background: rgba(0,0,0,0.02);
  border-radius: 2px;
}
.left-sidebar-add-new:hover i{
  color: $appPrimaryBlue;
}

td i {
  color: $appTextGrey;
  font-size: 1.188rem;
}

.group-form-select {
  max-width: 87%;
  display: inline-block;
}

.remove-group-btn {
  float: right;
  height: 49px
}
.left-sidebar-list {
  padding-right: 10px;
}
.search-filter {
  margin: 2px 0px 20px 0px;
}

.search-filter .form-control {
  padding: 0.4em 0.65em;
}

.input-group .btn-outline {
  color: $appSecondaryGrey;
  border-color: $appSecondaryGrey;
  border-radius: 2px;
  border-left: none;
  background: #fff;
}

.ui.dropdown:hover svg {
  border-radius: 3px;
}

.menu i.icon {
  font-size: 15px;
  color: $appSecondaryGrey;
}

.acg-list label {
  display: block;
  margin-left: 0;
  margin-bottom: 9px;
  padding: 0;
}

.form-switch .form-check-input:checked {
  background-color: $appPrimaryBlue;
  border-color: $appPrimaryBlue;
  cursor: pointer;
}
.form-check-input:focus {
  box-shadow: none;
}
.form-switch .form-check-input {
  width: 3.2em;
  height: 1.8em;
}



.control-plans {
  position: relative;
  z-index: 1;
}

.add-group-membership {
  margin-top: 5px;
}

.acg-list .form-check-input {
  margin-right: 7px;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}

.capitalize-name {
  text-transform: capitalize;
}

.text-primary .sort-icon {
  color: #ccc;
  position: relative;
  top: 7px;
  cursor: pointer;
}

.centered {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centered-v {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.centered-h {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
span.sidebar-list-item.text-truncate {
  width: 87%;
}

.card-body.no-top-padding {
  padding-top: 0;
}
.card-body-page-title {
  position: relative;
  top: 15px;
}

.card-body-page-subtitle {
  line-height: 14px;
  font-size: 16px;
  color: $appTextGrey;
  margin-top: 10px;
}

.card-body h2 {
  font-size: 26px;
  color: $appTextDarkGrey;
}

h2.page-title {
  display: inline-block;
  font-size: 1.938rem;
}

.empty-group-list {
  font-size: 16px;
  color: $appTextDarkGrey;
  padding: 1em 0em;
  
}
span.import-disabled-disclaimer {
  font-size: 16px;
  padding-bottom: 15px;
  display: block;
  color: $appTextDarkGrey;
  padding: 1em 0em;
}

.user-notification-container div {
  margin-bottom: 5px;
}

.notification-btn {
  position: relative;
  right: 30px;
  z-index: 3;
  float: right;
  top: 1px;
  cursor: pointer;
  font-size: 1.938rem;
}
.notification-bell-icon[data-count]:after {
  position: absolute;
  left: 6%;
  top: 28%;
  content: attr(data-count);
  font-size: 10px;
  padding: 4px;
  border-radius: 10px;
  line-height: 0.55em;
  color: white;
  background: #EF553F;
  font-family: $appFontMedium;
}
span.notification-bell-icon {
  font-size: 36px;
  color: $appTextDarkGrey;
}

.blue-indicator-dot {
  height: 8px;
  width: 8px;
  background-color: #175785;
  border-radius: 50%;
  display: flex;
  z-index: 999999;
  margin-left: -20px;
  float: left;
}


.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
  background-color: $appPrimaryBlue;
  border-color: $appPrimaryBlue;
}

.fa-spin.fa-circle-o-notch {
  transform-origin: 50% 48.95%;
}
.swyched-spinner {
  margin: 0 auto;
  text-align: center;
  color: #ddd;
}

.blinky {
  animation: blinky2 2s infinite linear both;
}

@keyframes blinky {
  0% { background: normal; }
  75% { background: normal; }
  87% { background: green; color: black; }
  100% { background: normal; }
}

@keyframes blinky2 {
  0% { }
  50% { background: #353; }
  100% { }
}


.avatar-editor-container {
  padding: 10px 0px 30px 0px;
}
.avatar-editor-container div {
  clear: both;
}
.avatar-preview {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  position: relative;
  top: 25%;
  transform: translateY(-50%);
  float: left;
  clear: both;
  border: 1px solid $appSecondaryGrey;
  padding: 10px;
  border-radius: 2px;
}
.avatar-preview-text {
  text-align: center;
  font-size: 16px;
  color: $appTextDarkGrey;
  float: left;
  padding-bottom: 5px;
  float: left;
}
.clear {
  clear: both;
}

hr {
  margin: 1em 0;
}

.app-left-logo {
  max-width: 100px;
}

.modal-body span.grey-label {
  color: $appTextGrey;
  line-height: 19px;
  font-size: 13px;
}

.vertical-tab-container  {
  padding-left: 0.2rem;
}

.vertical-tab-item {
  border-bottom: 1px solid #DCE3EB;
  padding: 1.4em 1em;
  cursor: pointer;
  font-size: 1.063rem;
}
.vertical-tab-item:last-of-type {
  border-bottom: none;
}

.vertical-tab-item:hover {
  background: #f9f9f9;
}

// .vertical-tab-item:hover .vertical-tab-header-icon svg path {
//   fill: $appTextDarkGrey;
// }

.modal-body div.vertical-tab-item {
  margin-bottom: 0;
  padding: 1.2em 0.5em;
}
.vertical-tab-item .expand-tab {
  font-size: 23px;
  margin-top: 3px;
}
.vertical-tab-item img {
  max-width: 20px;
  margin-right: 22px;
}
.vertical-tab-item span.vertical-tab-header-icon {
  padding-right: 22px;
  margin-top: -3px;
}
span.vertical-tab-header-icon svg {
  margin-top: 4px;
}

.vertical-tab-item .form-switch .form-check-input {
  width: 2.2em;
  height: 1.2em;
  margin-right: 15px;
}
.expand-tab {
  position: absolute;
  right: 40px;
}
.expand-tab svg {
  top: -8px;
  position: relative;
}

.vertical-tab-item .site-setting-header-icon {
  font-size: 25px;
}
.next-btn svg {
  margin-right: 0;
}

.pie-center-label {
  font-size: 1.1rem;
}

.notification-destination-config .label-detail {
  padding: 0;
  margin-top: 5px;
}

.net-energy-table .busy-container .swyched-spinner {
  font-size: 40px;
  color: #ccc;
}

.vertical-tab-content-body {
  padding: 1em 0.2em;
}

.search-filter .btn-outline {
  padding: 0 8px;
}



.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ddd;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ddd transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vertical-tab-item span{
  font-size: 1rem;
  color: $appSecondaryGrey;
}

.vertical-tab-item span.status-indicator{
  font-size: 1rem;
  color: $appSecondaryGrey;
  margin-right: 30px;
}

// Btn Primary
.btn {
  border-radius: 3px;
  font-size: 0.875rem;
  font-family: $appFontRegular;
  
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.back-btn {
  position: absolute;
  left: 10px;
}
.btn-back {
  border: 1px solid #bbb;
  color: #bbb;
}

.btn-back.active,
.btn-back:active {
  background: #bbb;
  border: 1px solid #bbb;
  color: #fff;
}

.btn-primary {
  border: 4px solid transparent;
  background: $appPrimaryBlue;
  border-color:$appPrimaryBlue;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #175785;
  border-color: #175785;
  box-shadow: 0 0 0 0.25rem rgb(23 87 133 / 30%);
}
.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(23 87 133 / 30%);
}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(23 87 133 / 30%);
}

.btn-primary:hover,
.btn-primary:active {
  color: #fff;
  background-color: rgba($appPrimaryBlue, .8);
  border-color: rgba($appPrimaryBlue, 0);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: $appTextGrey;
  background-color: rgba(193, 197, 200, 0.25);
  border-color: transparent;
}

.btn-primary:disabled svg {
    fill: $appTextGrey;
}

.btn i.without-text{
    padding-right: 0px;
}

.btn-white {
  border: 1px solid $appSecondaryGrey;
  background: #fff;
  color: $appTextDarkGrey;
}
// Btn Action

.btn-action i {
  position: relative;
  font-size: 1.25rem;
  transition: all .4s ease;
  padding: 16px 16px;
  border-radius: 3px;
  background-color: #fff;
  color: $appSecondaryGrey;
}

.btn-action:hover {
  background: rgba(193, 197, 200, 15%);
  border-radius: 3px;
}

.btn-action:hover svg path{
  background: rgba(193, 197, 200, 15%);
  color: $appTextDarkGrey;
  fill: $appTextDarkGrey;
}

.btn-action i.icon:before {
  background: 0 0!important;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.btn-icon-simple {
  color: $appSecondaryGrey;
  font-size: 20px;
}

// Btn UI dropdown
.ui.dropdown .menu > .item i {
  padding: 0;
}

.ui.dropdown .menu > .item:hover i {
  color: $appTextDarkGrey;
  background: none;
}

.ui.dropdown .menu > .item {
  color: $appTextDarkGrey;
  padding: 16px 20px !important;
  font-size: 0.875rem;
  font-family: $appFontRegular;
}
.menu .item svg {
  margin-right: 10px;
  float: none;
}

.ui.button.btn-secondary{
  background: none;
  font-family: $appFontRegular;
  border-radius: 3px;
  color: $appTextGrey;
  transition: all .4s ease;
}

.ui.active.button:active, 
.ui.active.button:focus, 
.ui.active.button:active svg path, 
.ui.active.button:focus svg path {
  background-color: #F6F7F7;
  background-image: none;
  color: $appTextDarkGrey;
  fill: $appTextDarkGrey;
}

.ui.dropdown .menu > .item svg path {
  fill: #C1C5C8;
}

.ui.labeled.icon.button>.icon,
.ui.labeled.icon.buttons>.button>.icon {
  background: none;
}

.ui.labeled.icon.button>.icon:after,
.ui.labeled.icon.button>.icon:before,
.ui.labeled.icon.buttons>.button>.icon:after,
.ui.labeled.icon.buttons>.button>.icon:before {
  position: relative;
  top: 3px;
  left: 5px;
  transform: none;
}

.ui.dropdown .menu>.item.active {
  color: #eee;
  background: $appTextDarkGrey;
}
.ui.dropdown .menu {
  border: 1px solid #DCE3EB;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 10px 25px -8px rgba(0, 58, 93, 0.2);
  border-radius: 3px;
}

.ui.dropdown .menu > .item:hover svg path {
  fill: $appTextDarkGrey;
}
.ui.button:hover i.icon {
  transition: all .4s ease;
}
.ui.button:hover i.icon:before {
  color: $appTextDarkGrey;
}
.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.primary-btn-icon {
  padding-right: 10px;
}

.ui.icon.button, .ui.icon.buttons .button {
  padding: 0.71571429em 0.78571429em 0.78571429em;
}

.ui.button {
  font-weight: 400;
}

.ui.dropdown>.text {
  display: inline-block;
  transition: none;
  line-height: 16px;
}

.ui.dropdown .menu>.divider {
  margin: 0;
}

.ui.dropdown .menu>.item:hover {
  background: rgba(193, 197, 200, 0.15);
  color: $appTextDarkGrey;
}

// Forms
.form-control {
  display: block;
  width: 100%;
  padding: 0.9em 0.65em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: $appTextDarkGrey;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid $appSecondaryGrey;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-size: 0.875rem;
}

.form-control:focus,
.form-select:focus {
  border-color: none;
  outline: none;
  box-shadow: none;
  border-color: $appPrimaryBlue;
}


.form-select, .form-control {
  font-size: 1rem;
  color: $appTextDarkGrey;
  border-color: #c1c5c8;
  border-radius: 3px;
  height: 33px;
  padding: 0rem 0.65rem;
}

.form-control.invalid {
  border-color: #EF553F;
}

.form-control::placeholder {
  color: $appTextGrey;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba($appSecondaryGrey, .25);
  border-color: rgba($appSecondaryGrey, 1);
}

textarea.form-control {
  min-height: 60px;
  padding: 6px;
}

.search-filter .form-control:focus, 
.search-filter .form-select:focus {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-right: none;
}

// Table

.table {
  width: 100%;
  margin-bottom: 0;
  color: $appTextDarkGrey;
  background-color: transparent;
  font-size: 1rem;
}

.table th, 
.table td {
  padding: 1.2em 1.2em;
  vertical-align: middle;
  border-bottom: none;
  padding-left: 36px;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 16px;
  border: 0;
  color: $appTextGrey;
  padding: 1em 1.2em;
  text-transform: uppercase;
  padding-left: 36px;
  font-weight: normal;
}

.table thead th {
  vertical-align: bottom;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: auto;
  
  th,	td {
		padding: 6px 8px;
		vertical-align: top;
    white-space: nowrap;
	}
  
  th {
		background: #ddd;
		text-align: left;
		white-space: nowrap;
	}
  
}

.scrollable {
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 1px solid $appSecondaryGrey;
  
  &.is-scrollable:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 50px;
		height: 100%;
		border-radius: 10px 0 0 10px / 50% 0 0 50%;
		box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
		content: '';
	}
  
  // the overflow-er
  > div {
		overflow-x: auto;
    
    // forces scrollbar for webkit
    ::-webkit-scrollbar {
      height: 12px;
    }
    
    ::-webkit-scrollbar-track {
      background: #f0f0f0;
      box-shadow: 0 0 2px rgba(0,0,0,0.15) inset;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 6px;
    }
	}
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  padding: .35em;
}

.table>thead {
  vertical-align: bottom;
  border-bottom: 1px solid $appSecondaryGrey;
}

table th,
table td {
  padding: .625em;
}

table th {
  font-size: .85em;
  font-weight: none;
}

.no-transform,
.table .no-transform{
  text-transform: none;
}

.search-filter .btn-outline {
  height: auto;
}

.acg-list .busy-container {
  font-size: 40px;
}

.signin-spinner-container .busy-container {
  margin-right: 5px;
}

.refresh-status-btn {
  float: right;
  display: none;
}

.signup-section h5 {
  text-align: center;
}

.verifying-credentials .swyched-spinner {
  font-size: 45px;
  margin: 0 auto;
  display: block;
  margin-top: 25px
}

.ant-popover-arrow {
  width: 20px;
  height: 20px;
}

.ant-popover-arrow-content {
  width: 20px;
  height: 20px;
} 

.modal-header {
  padding: 1.75em 2.5em;
}

.modal-body {
  padding: 1.5em 2.5em
}

.modal-footer {
  padding: 1.6em;
}

.ant-tree-select-dropdown {
  position: relative !important;
  top: 25px !important;
}

/* Beta styles */

.beta-tag {
  background: rgba($appPrimaryBlue, 1);
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  top: -2px;
  font-family: $appFontMedium;
  font-size: 12px;
  display: inline-block;
  padding: 0px 6px;
  margin-left: 15px;
  line-height: 22px;
}

span.beta-tag {
  position: absolute;
  top: 6px;
  padding: 0px 6px;
  line-height: 22px;
}

.menu > .item > span.description {
  background: rgba($appPrimaryBlue, 1);
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff !important;
  font-size: 12px;
  padding: 4px 6px;
  position: relative;
  top: -2px;
  font-family: $appFontMedium;
}

.anticon-close-circle {
  display: none;
}

.logout-icon-container {
  margin-right: 10px;
}

.close-drawer-btn {
  float: right;
  padding: 10px;
  cursor: pointer;
}

.close-drawer-btn:hover svg {
  fill: $appTextDarkGrey;
}

.list-group-horizontal {
  flex-direction: row;
}

.percentage-readout {
  font-size: 16px;
  color: $appTextGrey;
  margin-top: 8px;
}

.org-tree-menu-text {
  display: none;
}

.org-info-container {
  padding: 15px 20px 15px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #DCE3EB;
}

.org-info-container span {
  font-size: 1rem;
  color: $appTextDarkGrey;
}

.org-info-container .user-email {
  color: $appTextGrey;
  font-size: 14px;
}

.org-tree-menu-body .org-tree-menu-text {
  display: inline;
}

.org-tree-menu-body .org-select-icon {
  margin-right: 15px;
  float: left;
}

.ui.dropdown .menu>.item.mobile-site-option {
  display: none;
}

select option {
  padding: 4px 10px;
  background: #e5e5e5;
  border-radius: 2px;
}

select option:not(:checked) {
  background-color: #fff;
}

.testing {
  display: inline-block;
  position: relative;
  outline: 1px solid red;
  z-index: 999;
  cursor: pointer;
}

.status-warning-popover-trigger {
  margin-left: 5px;
}

.faulted-port-list div {
  color: $appTextDarkGrey;
  line-height: 26px;
}

.faulted-port-list span {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.ui.dropdown .menu {
  z-index: 9999;
}

.pull-right {
  float: right;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.big-check {
  width: 20px;
  height: 20px;
}

.filter-count {
  float: right;
  color: #175785;
  background: rgba(23, 87, 133, 0.1);
  border-radius: 3px;
  padding: 0px 6px;
  height: 24px;
  line-height: 24px;
  margin-top: 8px;
}

.ant-tree-select-dropdown {
  padding: 8px 0px 0px 0px !important;
}

.custom-sticky-signout {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  padding: 16px 46px;
  font-size: 16px;
  color: $appTextDarkGrey;
  border-top: 1px solid #DCE3EB;
  cursor: pointer;
}

.custom-sticky-signout:hover {
  background: #F6F7F7;
}

td:last-child {padding-right: 40px;}

.faulted-icon {
  display: none;
}

.faulted-status .faulted-icon {
  display: inline-block;
  position: absolute;
  top: -7px;
  right: 2px;
}

.ui.upward.dropdown>.menu {
  top: inherit !important;
}

.vertical-nav {
  padding: 45px 20px;
}

.vertical-nav div {
  padding: 1em;
  color: $appTextDarkGrey;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
}

.vertical-nav div:hover {
  background: rgba(193, 197, 200, 0.15);
  color: #175785;
}

.vertical-nav div:hover svg, 
.vertical-nav div:hover path{
  fill: #175785;
}

.vertical-nav span {
  margin-right: 15px;
}

.vertical-nav div svg {
  margin-right: 10px;
}

// Vertical nav WIP

// .top-nav.tab-control-tabs {
//   height: 100%;
//   width: 65px;
//   position: fixed;
// }

// .tab-tab.active-tab, .tab-tab.inactive-tab {
//   display: block;
// }

// .tab-tab span {
//   display: none;
// }

// .tab-tab {
//   padding: 17px 25px 15px 20px;
// }

// .tab-body {
//   padding-left: 100px;
// }

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   background: none;
// }

.create-group-mobile-btn {
  visibility: hidden;
}