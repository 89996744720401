.nested-tab-title,
.nested-tab-back {
  clear: both;
}
.nested-tab-title {
  cursor: pointer;
}
.nested-tab-back h5 {
  color: #666;
  font-size: 1.2rem;
  
  padding-bottom: 0.4em;
}
.nested-tab-back-btn {
  font-size: 1rem;
  
  cursor: pointer;
  padding: 0.5em 0em 1.5em 0em;
}

.nested-tab-back-btn span {
  vertical-align: top;
  margin-right: 12px;
  color: #C1C5C8;
  position: relative;
  top: -1px;
}